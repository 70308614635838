import React, { useState, useEffect } from 'react'
import styles from './CustomSteps.module.scss'
import { t } from '../../../config/i18n'
import cn from 'classnames'
import { MouseSensor, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { Steps, Modal } from 'antd-mobile';
import { DndContext, DragCancelEvent, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { DraggableAndDroppable } from './DraggableAndDroppable';
import { Action } from 'antd-mobile/es/components/modal';

export interface Item {
  id: any
  cancelled?: boolean
  title: [string, string]
  description: string
}

interface Props {
  current?: number
  items: Item[]
  onItemClick: (item: Item) => void,
  onItemDragAndDropChange: (itemFrom: Item, itemTo: Item) => void,
}

interface DndProps {
  from?: Item
  to?: Item
  idActiveDraggable?: any
  confirmed: boolean
  isModalActive: boolean
}

export const CustomSteps = ({
  current = 0, items,
  onItemClick = item => { },
  onItemDragAndDropChange = (itemFrom, itemTo) => { },
}: Props) => {
  const [dndData, setDndData] = useState<DndProps>({ confirmed: false, isModalActive: false })
  const ref = React.useRef() as React.RefObject<HTMLDivElement>;

  React.useLayoutEffect(() => {
    const dom = ref.current;
    if (!dom) return;
    const titles = dom.querySelectorAll('.' + styles.title);
    dom.style.paddingInlineStart = Math.max(
      ...[...titles]
        .map(d => d.children[0])
        .map(d => d.getBoundingClientRect())
        .map(r => r.width - 12)
    ) + 'px';
  }, [items.map(d => d.title[0]).join('')])

  useEffect(() => {
    if (dndData.confirmed) {
      setDndData({ confirmed: false, isModalActive: false })
      if (dndData.from && dndData.to) {
        onItemDragAndDropChange(dndData.from, dndData.to)
      }
    }
  }, [dndData])

  function handleDragStart (event: DragStartEvent) {
    if (event.active && event.active.id) {
      const filteredItem = items.filter(item => item.id === event.active!.id)
      if (filteredItem.length > 0) {
        setDndData({ ...dndData, idActiveDraggable: event.active.id, from: filteredItem[0], confirmed: false, isModalActive: false });
      } else {
        setDndData({ confirmed: false, isModalActive: false });
      }
    } else {
      setDndData({ confirmed: false, isModalActive: false });
    }
  }

  function handleDragEnd (event: DragEndEvent) {
    if (event.over && event.over.id && dndData.from && event.over.id !== dndData.from.id) {
      const filteredItem = items.filter(item => item.id === event.over!.id)
      if (filteredItem.length > 0 && dndData.from) {
        setDndData({ ...dndData, idActiveDraggable: undefined, to: filteredItem[0], confirmed: false, isModalActive: true });
      } else {
        setDndData({ confirmed: false, isModalActive: false });
      }
    } else {
      setDndData({ confirmed: false, isModalActive: false });
    }
  }

  function handleDragCancel (event: DragCancelEvent) {
    setDndData({ confirmed: false, isModalActive: false })
  }

  // Support onclick
  const sensors = useSensors(
    useSensor(MouseSensor, {
      onActivation: (event) => {},
      activationConstraint: {
        distance: 2,
      }
    }),
    useSensor(PointerSensor, {
      onActivation: (event) => {},
      activationConstraint: {
        distance: 4,
      }
    }),
    useSensor(TouchSensor, {
      onActivation: (event) => {},
      activationConstraint: {
        distance: 4,
      }
    }),
  );

  function closeModal() {
    setDndData({ confirmed: false, isModalActive: false })
  }

  function activateDnd(action: Action, idx: number) {
    if (action.key === "Confirm") {
      if (!dndData.confirmed && dndData.from && dndData.to) {
        setDndData({...dndData, confirmed: true, isModalActive: false})
      } else {
        setDndData({ confirmed: false, isModalActive: false })
      }
    } else if (action.key === "Cancel") {
      setDndData({ confirmed: false, isModalActive: false })
    }
  }
  
  function renderModalContent(dndData: DndProps) {
    if (!dndData.from || !dndData.to || !dndData.isModalActive || dndData.confirmed) {
      return "Error occured"
    }

    return (
      <div className={styles.rootModal}>
        <span className={cn(styles.modalTitle)}>
          {t.customStep.textChangeSlot}
        </span>
        <br />
        <Steps direction='vertical' className={styles.rootSteps}>
          <Steps.Step
            className={cn(styles.step)}
            key={dndData.from.id}
            status='finish'
            title={<div className={styles.title}>
              <div className={styles.ticketTime}>{dndData.from.title[0]}</div>
              <div className={styles.ticketTitle}>{dndData.from.title[1]}</div>
            </div>}
            description={dndData.from.description}
          />
          <Steps.Step
            className={cn(styles.step)}
            key={dndData.to.id}
            status='finish'
            title={<div className={styles.title}>
              <div className={styles.ticketTime}>{dndData.to.title[0]}</div>
              <div className={styles.ticketTitle}>{dndData.to.title[1]}</div>
            </div>}
            description={dndData.to.description}
          />
        </Steps>
      </div>
    )
  }

  return (
    <div
      ref={ref}
    >
      <Modal
        visible={dndData.isModalActive}
        content={renderModalContent(dndData)}
        onClose={closeModal}
        onAction={activateDnd}
        actions={[
          {
            key: "Confirm",
            text: t.customStep.confirm
          },
          {
            key: "Cancel",
            text: t.customStep.cancel
          }
        ]}
        closeOnAction
      />
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        sensors={sensors}
      >
        <Steps direction='vertical' className={styles.root}>
          {items.map((item: any, i: any) => (
            <DraggableAndDroppable
              className={cn(
                styles.dndStep
              )}
              id={item.id}
              idActiveDraggable={dndData.idActiveDraggable}
              onClick={(e) => {
                e.preventDefault()
                closeModal()
                onItemClick(item)
              }}
            >
              <Steps.Step
                className={cn(
                  i < current ? styles.past : i == current ? styles.current : styles.future,
                  item.cancelled && styles.cancelled
                )}
                key={item.id || i}
                status='finish'
                title={<div className={styles.title}>
                  <div className={styles.ticketTime}>{item.title[0]}</div>
                  <div className={styles.ticketTitle}>{item.title[1]}</div>
                </div>}
                description={item.description}
              />
            </DraggableAndDroppable>
          ))}
        </Steps>
      </DndContext>
    </div>
  );
}
